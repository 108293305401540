import React from 'react';
import Icon from '../SocialMediaIcons';

export default function SocialMediaIcons({ footer }) {
    return (
        <div className="social-medias max-w-[240px] flex w-full justify-between sm:max-w-[280px] flex-row text-4.5xl my-0 mx-auto pt-[3.75rem] pb-16">
            {footer?.socialMedia?.map((icon, index) => {
                const platform = icon?.socialMediaPlatform;
                return (
                    <div key={index}>
                        <Icon platform={platform} url={icon?.socialMediaPage?.url} />
                    </div>
                );
            })}
        </div>
    );
}
