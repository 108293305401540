import React from 'react';

import Instagram from 'public/images/SocialMedia/instagram.svg';
import Twitter from 'public/images/SocialMedia/twitterX.svg';
import Youtube from 'public/images/SocialMedia/youtube.svg';
import FacebookIcon from 'public/images/SocialMedia/facebook.svg';
import LinkedIn from 'public/images/SocialMedia/linkedin.svg';
import Email from 'public/images/SocialMedia/email.svg';
import Link from 'next/link';

export default function Icon({ platform, url }) {
    const socialMediaClasses = 'h-5 w-5 hover:text-lightBlue text-white';
    return (
        <Link aria-label={`Jews for Jesus - ${platform}`} title={`Jews for Jesus - ${platform}`} rel="noreferrer" target="_blank" href={url} passHref>
            {platform === 'Facebook' && <FacebookIcon className={socialMediaClasses} />}
            {platform === 'Instagram' && <Instagram className={socialMediaClasses} />}
            {platform === 'Twitter' && <Twitter className={socialMediaClasses} />}
            {platform === 'YouTube' && <Youtube className={socialMediaClasses} />}
            {platform === 'LinkedIn' && <LinkedIn className={socialMediaClasses} />}
            {platform === 'Email' && <Email className={socialMediaClasses} />}
        </Link>
    );
}
